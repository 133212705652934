@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Black";
  src: url("./assets/fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.appear {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  transform: translateX(0px) !important;
}
.title {
  opacity: 0;
  transition: opacity 1.5s, transform 1.5s;
  transform: translateY(50px);
}

.content {
  opacity: 0;
  transition: opacity 1.5s, transform 1.5s;
  transform: translateX(-50px);
}

.titleBlock {
  font-family: "Lato";
  padding-left: 100px;
  text-transform: uppercase;
}

.firstTitle {
  font-family: 'Lato';
  color: #7f899c;
  font-size: 40px;
  font-weight: 400;
  line-height: 38px;
  margin-bottom: 0;
  letter-spacing: 0.2em;
  margin-top: 50px;
  margin-bottom: 20px;
}

.secondTitle {
  font-size: 60px;
  font-family: 'Lato Black';
  font-weight: bold;
  
  letter-spacing: 0.1em;
  margin-top: 0;
}

*{
  color: #021032
}

h1 {
  font-family: "Lato Black";
  text-transform: uppercase;
}

h2 {
  font-family: "Nunito";
}

body {
  user-select: none;
  overflow-x: hidden;
}

img {
  -webkit-user-drag: none;
}

@media screen and (max-width: 840px) {
  .titleBlock {
    padding-left: 20px;
    margin-top: 20px;
  }
  .firstTitle{
    margin-top: 0px;
    font-size: 20px;
    line-height: 40px
  }
  .secondTitle{
    font-size: 30px;
    line-height: 40px ;
    margin-bottom: 0px;
    
  }
}